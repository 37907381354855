import gql from 'graphql-tag';

export default gql`
  query orders($sort: [OrderSort], $filter: OrderFilterInput, $pagination: Pagination, $regionCodes: [String]) {
    orders(sort: $sort, filter: $filter, pagination: $pagination, regionCodes: $regionCodes) {
      total
      totalPage
      data {
        id
        total
        orderDate
        deliveryTime {
          start
          end
        }
        status
        paymentMethod
        buyer {
          id
          name
        }
        store {
          id
          name
          telephone
          status
        }
        items {
          product {
            id
          }
        }
        orderType
        buyerNote
        zeroOrderInfo {
          reason
          receiver
          orderId
        }
      }
    }
  }
`;
